import React from 'react';
import { StaticPage, TopbarContainer } from '..';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  ExternalLink,
} from '../../components';
import handOne from '../AboutPage/images/handOne.png';
import hiFive from '../AboutPage/images/hi5.png';

import css from './GetInvolvedPage.module.css';

const GetInvolvedPage = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="Get Involved"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description:
          'How to get involved with Signalise',
        name: 'Get Involved Page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
         <div className={css.contentWrapper}>
          <h1 className={css.pageTitle}>Get Involved</h1>
           <div className={css.contentContainer}>
            <div className={css.contentSide}>
              <img src={hiFive} alt="Two Signalise hands happy" className={css.hiFive} />
            </div>
            <div className={css.contentMain}> 
              <section>
                <h2>Volunteer</h2>
                <p>We really appreciate the time we've received from volunteers.
                There's a lot involved in setting up and running a co-operative. 
                The rewards are huge. Not just for interpreters and Deaf people on Merseyside, 
                but for the whole community. </p>
                <p>If you are a passionate individual with any time to spare, even an hour a week,
                  we'd love to hear from you. Can you gift your time to do any of the following: </p>
                <ul className={css.list}>
                  <li> Digging around, doing market research and getting intel to help us to grow quicker</li>
                  <li> Being an admin whizz: creating and proofreading documents, organising</li>
                  <li> Fancy some comms work? Writing, blogging, interviewing, video-editing - any or all of these</li>
                  <li>Researching key contacts for some exciting upcoming work</li>
                  <li> Being a techy: help test our systems; we're growing quick and need you to spot where we can improve</li>
                </ul>
                <p>Email us at <a href="mailto:hello@signalise.coop?subject=Volunteer%20Offer&#58;%20I&#39;m%20in&#33;&body=
                  Hello%0D%0A%0D%0AI&#39;m%20in%20and%20would%20love%20to%20volunteer.%0D%0A%0D%0A
                  Here%20is%20what%20I&#39;m%20interested%20in&#58;%0D%0A%0D%0A
                ...%0D%0A%0D%0A">hello@signalise.coop</a> to find out more!</p>
              </section>
              <section>
                <h2>Stay in touch</h2>
                <p> We can send you updates on our work and sneak previews of what we are doing. </p>
                                    <NamedLink name="MailchimpSignupPage" className={css.bookingButton}>
                      Sign Up to receive updates
                    </NamedLink>  
              </section>

              <section>
                <h2>Become a member</h2>
                <p>If you are a Deaf person or interpreter, in Merseyside, and not yet a member, click here to learn more about joining</p>
                                    <NamedLink name="JoinUsPage" className={css.bookingButton}>
                      Join Us
                    </NamedLink>  
              </section>

            </div>
           </div>
          </div> 
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default GetInvolvedPage;
